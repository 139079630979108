
.modal {
  position: fixed;
  inset: 0; /* inset sets all 4 values (top right bottom left) much like how we set padding, margin etc., */
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 99;
  padding: 40px 20px 20px;
  opacity: 0.8;
  pointer-events: none;
  transform: scale(0.4);
}

.modal-enter-done {
  /*opacity: 0.5;*/
  pointer-events: auto;
  transform: scale(1);
}
.modal-exit {
  /*opacity: 0;*/
  transform: scale(0.4);
}


.modal-content {
  background-color: #282c34;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  opacity: 1;
  border: 2px solid darkslategrey;
}